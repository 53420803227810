import type { App } from "vue";
import { defineAsyncComponent } from "vue";

export function registerGlobalComponents(app: App): void {
  app.component(
    "pe-input",
    defineAsyncComponent(() => import("@/components/library/PeInput.vue"))
  );
  app.component(
    "pe-input-file",
    defineAsyncComponent(() => import("@/components/library/PeInputFile.vue"))
  );
  app.component(
    "pe-select",
    defineAsyncComponent(() => import("@/components/library/PeSelect.vue"))
  );
  app.component(
    "pe-btn",
    defineAsyncComponent(() => import("@/components/library/PeButton.vue"))
  );
  app.component(
    "pe-toggle",
    defineAsyncComponent(() => import("@/components/library/PeToggle.vue"))
  );
  app.component(
    "pe-switch",
    defineAsyncComponent(() => import("@/components/library/PeSwitch.vue"))
  );
  app.component(
    "pe-radio",
    defineAsyncComponent(() => import("@/components/library/PeRadio.vue"))
  );
  app.component(
    "pe-tabs",
    defineAsyncComponent(() => import("@/components/library/PeTabs.vue"))
  );
  app.component(
    "pe-notification",
    defineAsyncComponent(() => import("@/components/library/PeNotification.vue"))
  );
  app.component(
    "pe-feedback",
    defineAsyncComponent(() => import("@/components/library/PeFeedback.vue"))
  );
  app.component(
    "pe-steps",
    defineAsyncComponent(() => import("@/components/library/PeSteps.vue"))
  );
  app.component(
    "pe-calendar",
    defineAsyncComponent(() => import("@/components/library/PeCalendar.vue"))
  );
  app.component(
    "pe-combobox",
    defineAsyncComponent(() => import("@/components/library/PeCombobox.vue"))
  );
  app.component(
    "pe-popover",
    defineAsyncComponent(() => import("@/components/library/PePopover.vue"))
  );
  app.component(
    "pe-table",
    defineAsyncComponent(() => import("@/components/library/PeTable.vue"))
  );
  app.component(
    "pe-table-td",
    defineAsyncComponent(() => import("@/components/library/PeTableTd.vue"))
  );
  app.component(
    "pe-pagination",
    defineAsyncComponent(() => import("@/components/library/PePagination.vue"))
  );
  app.component(
    "pe-pill",
    defineAsyncComponent(() => import("@/components/library/PePill.vue"))
  );
  app.component(
    "pe-chart",
    defineAsyncComponent(() => import("@/components/library/PeChart.vue"))
  );
  app.component(
    "pe-listbox",
    defineAsyncComponent(() => import("@/components/library/PeListbox.vue"))
  );
  app.component(
    "pe-radio-group",
    defineAsyncComponent(() => import("@/components/library/PeRadioGroup.vue"))
  );
  app.component(
    "pe-checkbox",
    defineAsyncComponent(() => import("@/components/library/PeCheckbox.vue"))
  );
  app.component(
    "pe-select-multiple",
    defineAsyncComponent(() => import("@/components/library/PeSelectMultiple.vue"))
  );
}
