import {
  ActionsParticulier,
  PaginateUsers,
  User,
  UserHistorical,
  UserHistoricalDetails
} from "@/interfaces/user.interface";
import { UserDto, UserDtoAsManager } from "@/validations/users/User.dto";
import { EditUserDtoAsAdmin, UserDtoAsAdmin } from "@/validations/users/UserAsAdmin.dto";
import type { UpdateCoachDto } from "@/validations/users/UpdateCoach.dto";
import type { CoachToImport } from "@/interfaces/coach.interface";
import Method from "@/utils/axiosMethods";
import { ApiResult } from "@/interfaces/api.interface";
import { ParticulierDtoAsAdmin } from "@/validations/users/ParticulierAsAdmin.dto";
import { InfosParticulier } from "@/interfaces/infosParticulier.interface";
import { formatISO } from "date-fns";

const URL_BASE: string = "/api/v1/users";

export async function getUsersWithParameters(
  roleId: number,
  companyId: number,
  page: number
): Promise<ApiResult<PaginateUsers>> {
  return await Method.get(`${URL_BASE}/params?role=${roleId}&company=${companyId}&page=${page}`);
}

export async function getUsersWithParametersForAdmin(
  roleId: number,
  companyId: number,
  page: number
): Promise<ApiResult<PaginateUsers>> {
  return await Method.get(
    `${URL_BASE}/admin/params?role=${roleId}&company=${companyId}&page=${page}`
  );
}

export async function getParticuliersForAdmin(page: number): Promise<ApiResult<PaginateUsers>> {
  return await Method.get(`${URL_BASE}/admin/particuliers?page=${page}`);
}

export async function getEmployeeFromCompany(
  companyId: number,
  takeLocked?: boolean
): Promise<ApiResult<User[]>> {
  return await Method.get(`${URL_BASE}/company/${companyId}?takeLocked=${takeLocked}`);
}

export async function getAllMultiUser(ids: number[]): Promise<ApiResult<User[]>> {
  return await Method.post(`${URL_BASE}/multi-users`, ids);
}

export async function countAllByCompany(companyId: number): Promise<ApiResult<number>> {
  return await Method.get(`${URL_BASE}/count/company/${companyId}`);
}

export async function getCoachs(page?: number): Promise<ApiResult<PaginateUsers>> {
  return await Method.get(`${URL_BASE}/params?role=${2}&page=${page}`);
}

export async function getCoachList(): Promise<ApiResult<CoachToImport[]>> {
  return await Method.get("/api/v1/tsugo/coachs/list");
}

export async function getAllCoachsNotInDb(): Promise<ApiResult<CoachToImport[]>> {
  return await Method.get("/api/v1/tsugo/coachs/not-in-db");
}

export async function getParticuliersNotInDb(): Promise<ApiResult<User[]>> {
  return await Method.get("/api/v1/tsugo/particuliers/not-in-db");
}

export async function importCoachs(ids: number[]): Promise<ApiResult<boolean>> {
  return await Method.post("/api/v1/tsugo/coachs/import", { ids });
}

export async function importParticuliers(ogustIds: number[]): Promise<ApiResult<boolean>> {
  const { status, error } = await Method.post(`${URL_BASE}/create/particuliers`, { ogustIds });
  return { data: status === 201, error };
}

export async function getUserById(id: number): Promise<ApiResult<User>> {
  return await Method.get(`${URL_BASE}/${id}`);
}

export async function createUser(user: UserDtoAsAdmin): Promise<ApiResult<User>> {
  user.email = user.email.toLowerCase();
  return await Method.post(URL_BASE, user);
}

export async function createUserAsManager(user: UserDtoAsManager): Promise<ApiResult<User>> {
  user.email = user.email.toLowerCase();
  return await Method.post(URL_BASE, user);
}

export async function signUp(
  email: string,
  password: string,
  code: string
): Promise<ApiResult<User>> {
  return await Method.post(`${URL_BASE}/sign-up`, {
    email: email.toLowerCase(),
    password,
    code
  });
}

export async function removeFirstLogin(id: number): Promise<ApiResult<boolean>> {
  const { status, error } = await Method.get(`${URL_BASE}/remove-first-login/${id}`);
  return { data: status === 204, error };
}

export async function updateUser(id: number, user: UserDto): Promise<ApiResult<User>> {
  user.email = user.email.toLowerCase();
  return await Method.patch(`${URL_BASE}/${id}`, user);
}

export async function updateUserCoach(
  id: number,
  coach: UpdateCoachDto,
  image?: File
): Promise<ApiResult<User>> {
  const formData = new FormData();

  if (image) formData.append("image", image);
  if (coach.birthDate) formData.append("birthDate", formatISO(coach.birthDate));

  formData.append("firstname", coach.firstname);
  formData.append("lastname", coach.lastname);
  formData.append("email", coach.email.toLowerCase());
  formData.append("phone", coach.phone);
  formData.append("about", coach.about);

  return await Method.patch(`${URL_BASE}/update/coach/${id}`, formData, {
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export async function updateUserAsAdmin(
  id: number,
  user: EditUserDtoAsAdmin
): Promise<ApiResult<User>> {
  user.email = user.email.toLowerCase();
  return await Method.patch(`${URL_BASE}/admin/${id}`, user);
}

export async function updateParticulierAsAdmin(
  id: number,
  user: ParticulierDtoAsAdmin
): Promise<ApiResult<User>> {
  user.email = user.email.toLowerCase();
  return await Method.patch(`${URL_BASE}/admin/particulier/${id}`, user);
}

export async function updateOwnPwd(id: number, password: string): Promise<ApiResult<boolean>> {
  return await Method.patch(`${URL_BASE}/change-password/${id}`, { password });
}

export async function deleteUserById(id: number, keep?: number): Promise<ApiResult<string>> {
  const url: string = keep ? `${URL_BASE}/${id}?keep=${keep}` : `${URL_BASE}/${id}`;
  return await Method.delete(url);
}

export async function unlockParticipant(email: string): Promise<ApiResult<string>> {
  return await Method.get(`${URL_BASE}/unlock/${email}`);
}

export async function updateHavePaid(id: number) {
  return await Method.patch(`${URL_BASE}/${id}/havePaid`);
}

export async function updateDechargeStatus(id: number): Promise<ApiResult<string>> {
  return await Method.patch(`${URL_BASE}/${id}/decharge`);
}

export async function getDecharge(id: number, toPdf: boolean): Promise<ApiResult<string>> {
  return await Method.get(`${URL_BASE}/${id}/decharge?toPdf=${toPdf}`, {
    responseType: "blob"
  });
}

export async function getParticipantHistorical(id: number): Promise<ApiResult<UserHistorical>> {
  return await Method.get(`${URL_BASE}/${id}/historical`);
}

export async function getParticipantHistoricalDetails(
  id: number
): Promise<ApiResult<UserHistoricalDetails[]>> {
  return await Method.get(`${URL_BASE}/${id}/historical/details`);
}

// Onglet *Information* sur Ogust
export async function getInfosParticulier(idOgust: number): Promise<ApiResult<InfosParticulier>> {
  return await Method.get(`${URL_BASE}/particulier/${idOgust}/infos`);
}

export async function getActionsParticulier(
  userId: number,
  ogustId: number
): Promise<ApiResult<ActionsParticulier>> {
  return Method.post(`${URL_BASE}/particulier/actions`, { userId, ogustId });
}
