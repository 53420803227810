import { createApp } from "vue";
import { createPinia, Pinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import Vue3Lottie from "vue3-lottie";
import "./assets/css/tailwind.css";
import "./assets/css/calendar.css";
import "vue-cal/dist/vuecal.css";
import { IndexedDatabase } from "./utils/indexedDatabase";
import { createHead } from "@unhead/vue";
import { setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale/fr";
import { registerGlobalComponents } from "@/plugins/pe.components";

const app = createApp(App);
const pinia: Pinia = createPinia();
const database: IndexedDatabase = new IndexedDatabase();
const head = createHead();

setDefaultOptions({ locale: fr });
registerGlobalComponents(app);

app
  .provide("database", database)
  .use(pinia)
  .use(router)
  .use(Vue3Lottie, { name: "Vue3Lottie" })
  .use(head)
  .mount("#app");
