<script setup lang="ts">
import { computed } from "vue";
import { registerSW } from "virtual:pwa-register";

type OS = "macos" | "ios" | "windows" | "android" | "linux";

function getOS(): OS | null {
  let userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    iosPlatforms = /(iphone|ipad|ipod)/i,
    os: OS | null = null;

  if (macosPlatforms.test(userAgent)) {
    os = "macos";
  } else if (iosPlatforms.test(userAgent)) {
    os = "ios";
  } else if (windowsPlatforms.test(userAgent)) {
    os = "windows";
  } else if (/android/.test(userAgent)) {
    os = "android";
  } else if (/linux/.test(userAgent)) {
    os = "linux";
  }

  return os;
}

// C'est utilisé pour le font-family
const getFontFamily = computed<string>(() => {
  const os: OS | null = getOS();
  if (["windows", "linux"].includes(os!)) return "'Inter', sans-serif";
  return "";
});

/*
  Interval pour checker si une nouvelle version est disponible
  On check toutes les minutes pour pas spam mais pour être sur
  que les utilisateurs aient la dernière version
 */
const updateInterval = 60 * 1000;
const updateSW = registerSW({
  onRegisteredSW(swUrl: string, r: ServiceWorkerRegistration | undefined) {
    r &&
      setInterval(async () => {
        if (!(!r.installing && navigator)) return;

        if ("connection" in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache"
          }
        });

        if (resp?.status === 200) await r.update();
      }, updateInterval);
  }
});
</script>

<template>
  <RouterView />
  <pe-notification />
</template>

<!-- Initialize all classes stock in database -->
<style class="text-[#155247] text-[#BA7637] text-[#B72020] text-[#086EB2] text-[#8289AE]">
@font-face {
  font-family: "Inter";
  src: url("@/assets/fonts/Inter.woff2");
}

/*
  TOUCHE PAS À CA, C'EST POUR LE SCROLL DU BODY
  CAR SAFARI CA PUE DU CUL
 */
html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}
body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  touch-action: manipulation; /* Supprime le double tab pour zoomer sur mobile */
}
/*
  APRÈS TU PEUX SUPPRIMER STV RAF
 */
#app {
  width: 100vw;
  display: flex;
  align-items: flex-start;
}

@supports (height: 100dvh) {
  #app {
    height: 100dvh;
  }
}

@supports not (height: 100dvh) {
  #app {
    height: 100vh;
  }
}

:root {
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
}

* {
  font-family: v-bind("getFontFamily");
}
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f9fafb;
}
*::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 999px;
  border: 2px #f9fafb solid;
  background-clip: padding-box;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #9ca3af;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.checkbox-lc-primary:checked {
  background-color: #232e69 !important;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(231, 50%, 27%) 65.99%),
    linear-gradient(45deg, transparent 75%, hsl(231, 50%, 27%) 75.99%),
    linear-gradient(-45deg, hsl(231, 50%, 27%) 40%, transparent 40.99%),
    linear-gradient(
      45deg,
      hsl(231, 50%, 27%) 30%,
      hsl(0, 0%, 100%) 30.99%,
      hsl(0, 0%, 100%) 40%,
      transparent 40.99%
    ),
    linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(231, 50%, 27%) 50.99%) !important;
}
</style>
